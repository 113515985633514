<template>
  <header class="header">
    <div class="header-inner">
      <div class="left">
        <img src="../../assets/images/bbs/panyou_logo.png" class="logo" />
        <div class="form-wrap">
          <div class="search-form">
            <input
              autocomplete="off"
              type="text"
              placeholder="请输入你要搜索的关键词"
              class="input"
              @click.stop=""
              @keyup.enter="submit"
              v-model="keyword"
              autofocus
            />
            <span class="search-button" @click="submit"> </span>
          </div>
        </div>
      </div>

      <div class="right">
        <span class="login-text" @click="showLogin">注册/登录</span>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
    }
  },
  mounted() {},
  methods: {
    submit() {
      return this.$message.warning("登录后才可以搜索")
    },
    async showLogin() {
      return this.$message.warning("当前尚未开放注册")
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_base.scss";
@import "@/assets/scss/_var.scss";
.header {
  padding: 5px 0;
  background-color: #fff;
  z-index: 10;
  position: sticky;
  top: 0;
  box-shadow: 0 0 1px 1px rgba(28, 28, 32, 0.05),
    0 8px 24px rgba(28, 28, 32, 0.06);
  .header-inner {
    width: $theme-width;
    margin: 0 auto;
    display: flex;
    max-width: 100%;
    min-height: 36px;
    justify-content: space-between;
    align-items: center;
    color: #333;
    .login-text {
      cursor: pointer;
      margin-left: 20px;
    }
    .site-name {
      font-size: 18px;
      font-weight: bold;
    }
    .right,
    .left {
      display: flex;
      align-items: center;
    }
    .logo {
      height: 50px;
      margin-right: 10px;
    }

    .search-form {
      position: relative;
      display: inline-block;
      .input {
        width: 220px;
        font-size: 13px;
        border-radius: 30px;
        height: 36px;
        padding-left: 10px;
        padding-right: 50px;
        outline: none;
        border: 1px solid #ddd;
        background-color: #eee;
        transition: all ease 0.3s;
      }
      .search-button {
        cursor: pointer;
        cursor: pointer;
        display: inline-block;
        position: absolute;
        bottom: 5px;
        width: 28px;
        right: 5px;
        top: 5px;
        z-index: 2;
        outline: none;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: all ease 0.2s;
        background: url("../../assets/images/search.png") no-repeat center
          center;
        background-size: 14px 14px;
      }
    }
  }
}
@media (max-width: 700px) {
  .header {
    padding: 5px 10px;
    .header-inner {
      .logo {
        height: 30px;
      }
      .search-form {
        .input {
          width: 150px;
        }
      }
    }
  }
}
</style>
