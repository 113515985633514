<template>
  <div class="detail-page">
    <HeaderComponent />
    <div class="detail-page-main">
      <div class="loading-wrap" v-if="loading">
        <lottie-player
          class="animation"
          src="https://res.yimiaopan.com/pan/lottie/search.json"
          background="transparent"
          speed="0.4"
          loop
          autoplay
        />
      </div>
      <template v-if="detail && detail.filename">
        <div class="bread-card">
          <p><span>首页</span> / <span>分享</span> / <span>正文</span></p>
        </div>
        <div class="detail-page-inner">
          <h1>{{ detail.filename }}</h1>
          <div class="userinfo">
            <img :src="avatar" class="avatar" />
            <span class="dot">·</span>
            <h4 class="username">
              {{ nickname }} <span style="color: #466fff">(盘主)</span>
            </h4>
            <span class="dot">·</span>
            <p class="createtime">
              {{ detail.utime && detail.utime.slice(0, 10) }}
            </p>
            <span class="dot">·</span>
            <van-icon name="fire-o" />8
          </div>
          <div class="brief">这个人很懒，什么也没留下~</div>

          <div class="detail-content">
            <div
              class="detail-wrap"
              v-if="detail.filelist && detail.filelist.length > 1"
            >
              <template v-for="(detailItem, detailIndex) in detail.filelist">
                <p
                  class="detail-item"
                  v-if="detailIndex > 0"
                  :key="`detail${detailIndex}`"
                  v-html="detailItem.filename"
                />
              </template>
            </div>
            <p class="download-link">
              <a :href="detail.url" target="_blank">{{ detail.url }}</a>
              <br />
              <span v-if="pwd">提取码：{{ pwd }}</span>
            </p>
            <div class="tag-wrap">
              <el-tag type="info"><i class="el-icon-star-off"></i>收藏</el-tag>
              <el-tag type="info"><i class="el-icon-thumb"></i>给力</el-tag>
              <el-tag type="info"
                ><i class="el-icon-warning-outline"></i>举报</el-tag
              >
            </div>
            <div id="divtort">
              <div
                style="
                font-size: 12px;
                color: #999;
                text-align: left;
                line-height: 22px;
                background: #f7f7f7;
                padding: 10px 20px;
                margin-bottom: -7px;
                margin-top: 10px;
              "
              >
                本帖子由用户自发分享，不代表本站观点。请注意甄别内容中的联系方式、诱导购买等信息，谨防诈骗。如发现有害或侵权内容，请点击<a
                  style="color: #155d9a; cursor: pointer; white-space: nowrap"
                  :href="`http://report.3453k.cn?fid=${id}&type=${type}`"
                  target="_blank"
                  >一键举报。</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="comment-wrap">
          <h4>最新回复</h4>

          <div class="comment-input">
            <div class="left">
              <img src="../../assets/images/bbs/panyou_avatar.png" />
              <p>匿名用户</p>
            </div>
            <textarea></textarea>
          </div>
          <div class="button-wrap">
            <button @click="showLogin">回帖</button>
          </div>
        </div>
      </template>

      <div class="no-result" v-if="!loading && (!detail || !detail.filename)">
        <p>内容已被管理员删除！</p>
      </div>
    </div>
    <FooterComponent style="margin-top: 20px" />
  </div>
</template>
<script>
import HeaderComponent from "./Header.vue";
import FooterComponent from "./Footer.vue";
import { getDetail } from "../../api/search";
import { randomAvatar, randomNickname } from "../../utils/index";
console.log(randomAvatar(), randomNickname());
const formatUrl = (url) => {
  if (!url) {
    return "";
  }
  const index = url.lastIndexOf("http");
  if (index > 0) {
    return url.slice(index);
  } else {
    return url;
  }
};

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data: () => ({
    id: "",
    pwd: "",
    url: "",
    type: "",
    parent: "",
    detail: null,
    loading: true,
    avatar: randomAvatar(),
    nickname: randomNickname(),
  }),
  async mounted() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.parent = this.$route.query.parent || "";
    if (this.id) {
      await this.getResourceDetail();
    }
  },
  methods: {
    async showLogin() {
      return this.$message.warning("暂未开放评论");
    },
    async getResourceDetail() {
      this.loading = true;
      if (this.id) {
        this.detail = await getDetail({
          id: this.id,
          type: this.type,
          parent: this.parent,
          size: 15,
        });
        if (this.detail && this.detail.filename) {
          this.pwd = this.detail.pwd;
        }
        if (this.detail && this.detail.url) {
          this.url = formatUrl(this.detail.url);
          this.getResourceUrl();
        }
      }
      this.loading = false;
    },

    async getResourceUrl() {
      if (
        this.type === "aliyundrive" &&
        this.parent &&
        this.parent != "undefined"
      ) {
        this.url = `${this.url}/folder/${this.parent}`;
      }
      if (this.pwd) {
        if (this.url.lastIndexOf("?") > -1) {
          this.url = `${this.url}&pwd=${this.pwd}`;
        } else {
          this.url = `${this.url}?pwd=${this.pwd}`;
        }
      }

      if (this.url.lastIndexOf("?") > -1) {
        this.url = `${this.url}&entry=dalipan`;
      } else {
        this.url = `${this.url}?entry=dalipan`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.detail-page {
  min-height: 100vh;
  background-color: #dee2e6;
  .detail-page-main {
    min-height: calc(100vh - 300px);
  }
}

.bread-card {
  margin: 15px auto;
  border-radius: 2px;
  padding: 10px 15px;
  font-size: 14px;
  width: $theme-width;
  max-width: 100%;
  color: #999;
  border-bottom: unset;
  background: #f9f9f9;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.no-result {
  width: $theme-width;
  max-width: 100%;
  margin: 15px auto;
  font-size: 20px;
  color: #666;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 40px;
  text-align: center;
}

.detail-page-inner,
.comment-wrap {
  width: $theme-width;
  max-width: 100%;
  margin: 15px auto;
  padding: 30px 0 60px;
  font-size: 14px;
  color: #666;
  border-radius: 2px;
  background-color: #fff;
  padding: 20px;
  line-height: 24px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  h1 {
    color: #212529;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  .userinfo {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    .dot {
      padding: 0 5px;
    }
    img {
      height: 24px;
      border-radius: 50%;
      border: 1px solid #e4ebf6;
    }
  }
  .brief {
    padding: 10px;
    flex-wrap: wrap;
    word-break: break-all;
    color: #999;
    background: #fafafa;
    margin-top: 5px;
    margin-bottom: 10px;
    position: relative;
  }
  .tag-wrap {
    margin-top: 40px;
    text-align: center;
    .el-tag {
      margin-right: 15px;
      font-size: 13px;
      i {
        margin-right: 5px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .detail-content {
    border-top: 1px solid #f6f6f6;
    padding: 15px 0;
  }
  .detail-wrap {
    color: #667;
    line-height: 1.8;
    font-size: 13px;
  }
  .download-link {
    margin-top: 20px;
  }
  a {
    color: #4876db;
    border-bottom: 1px solid #e4ebf6;
  }
}
.comment-wrap {
  margin-bottom: 20px;
  .comment-input {
    margin-top: 15px;
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      margin-right: 15px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #ddd;
        margin-bottom: 6px;
      }
    }
    textarea {
      flex: auto;
      background-color: rgb(245, 246, 247);
      height: 150px;
      border: 1px solid #eee;
      border-radius: 4px;
      height: auto;
      line-height: 20px;
      padding: 6px 10px;
      resize: vertical;
    }
  }
  .button-wrap {
    margin-top: 10px;
    text-align: right;
    button {
      cursor: pointer;
      background: linear-gradient(
        129.12deg,
        #446dff 0%,
        rgba(99, 125, 255, 0.75) 100%
      );
      box-shadow: 0 16px 48px rgba(38, 38, 38, 0.12);
      backdrop-filter: blur(50px);
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      font-size: 12px;
      border: none;
      outline: none;
      color: #fff;
    }
  }
}
.loading-wrap {
  text-align: center;
  .animation {
    height: 160px;
  }
}
@media (max-width: 700px) {
  .detail-page-inner {
    .userinfo {
      flex-wrap: wrap;
    }
  }
}
</style>
