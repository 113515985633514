<template>
  <footer class="footer">
    <div class="copyright-wrap">
      <p>
        一个好玩有趣的社区~ <br />
        一个优质的云盘资源分享社区~
      </p>
      <p>Copyright © {{ year }}. All Rights Reserved.</p>
      <div class="exemption">
        <p>
          ©
          免责声明：本站所有帖子均来自网友自发分享，本站仅提供交流平台并不支持任何储存、修改、下载，且已对搜索引擎进行协议屏蔽，如有侵权请联系我们清除处理。【<a
            :href="`http://report.3453k.cn?fid=${id}`"
            target="_blank"
            style="color: red"
            >点击举报</a
          >】。
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      year: new Date().getFullYear(),
    }
  },
}
</script>
<style lang="scss" scoped>
.footer {
  background: #343a40;
  padding: 20px;
  color: #999;
  .exemption {
    color: #999;
    font-size: 13px;
    margin-top: 10px;
    padding: 10px 5px;
    background: #31353a;
    text-align: center;
  }
}
.copyright-wrap {
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
  padding: 15px;
  width: 900px;
  max-width: 100%;
  p {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
